import React from "react";

type PageLoaderProps = {
  isDark?: boolean;
};

const PageLoader: React.FC<PageLoaderProps> = ({ isDark = false }) => {
  const boxStyle = `w-6 h-6 rounded-lg ${
    isDark ? "bg-phpc-blue" : "bg-blue-phpr"
  } animate-bounce`;
  return (
    <div
      data-testid="loading-screen"
      style={{ zIndex: 1 }}
      className={`flex flex-col space-x-2 items-center w-full h-full justify-center ${
        isDark ? "page-height" : ""
      }`}
    >
      <div className="flex flex-col items-center justify-center space-y-4 absolute top-1/4">
        <div className="flex items-center justify-center space-x-3">
          <div className={boxStyle}></div>
          <div className={boxStyle} style={{ animationDelay: "200ms" }}></div>
          <div className={boxStyle} style={{ animationDelay: "400ms" }}></div>
        </div>
        <p
          className={`${
            isDark ? "text-white" : "text-gray-600"
          } font-bold text-xl`}
        >
          Loading...
        </p>
      </div>
    </div>
  );
};

export default PageLoader;
