// Axios
import axios from "axios";

// Redux
import { createAsyncThunk } from "@reduxjs/toolkit";

// Cookie
import cookie from "js-cookie";

type LoginThunkArgs = {
  username: string;
  password: string;
};

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (args: LoginThunkArgs, _) => {
    try {
      const { data } = await axios.post("/api/auth", args);

      const { token, user } = data;

      return {
        token,
        user,
      };
    } catch (e: any) {
      if (e?.response?.status === 401) {
        throw new Error("Invalid credentials");
      } else {
        throw new Error(e?.response?.statusText);
      }
    }
  }
);

export const initialLoginThunk = createAsyncThunk(
  "auth/initial-login",
  async () => {
    try {
      const { data } = await axios.get("/api/verify");

      const { token, user } = data;

      return {
        token,
        user,
      };
    } catch (e: any) {
      if (e?.response?.status === 401) {
        throw new Error("Invalid credentials");
      } else {
        throw new Error(e?.response?.statusText);
      }
    }
  }
);
