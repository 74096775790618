import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";

// axios
import axios from "axios";

// types
import { IFQCN_BUI } from "@/utils/types/common";
import {
  redirect,
  usePathname,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { useLocale } from "next-intl";
import CallAPI from "@/utils/functions/callAPI";

import cookies from "js-cookie";

interface DetailedShowContextProps {
  data: any;
  loading: boolean;
  fetchData: () => void;
  fqcn_bui: IFQCN_BUI;
  primaryImage: any;
  imageLoading?: boolean;
  managingPage: boolean;
  setManagingPage: React.Dispatch<React.SetStateAction<boolean>>;
  managingTranslations: boolean;
  setManagingTranslations: React.Dispatch<React.SetStateAction<boolean>>;
}

const DetailedShowContext = createContext<DetailedShowContextProps>({
  data: {},
  loading: true,
  fetchData: () => {},
  fqcn_bui: {
    Bundle: "",
    Unit: "",
    Interface: "",
  },
  primaryImage: "",
  imageLoading: true,
  managingPage: false,
  setManagingPage: () => {},
  managingTranslations: false,
  setManagingTranslations: () => {},
});

const DetailedShowContextProvider: React.FC<{
  children: ReactNode;
  resource: string;
  fqcn_bui: IFQCN_BUI;
}> = ({ children, resource, fqcn_bui }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [primaryImage, setPrimaryImage] = useState<any>("");
  const [imageLoading, setImageLoading] = useState(true);
  const [managingPage, setManagingPage] = useState(false);
  const [managingTranslations, setManagingTranslations] = useState(false);

  const locale = useLocale();

  const { push } = useRouter();
  const pathname = usePathname();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await CallAPI(
        "GET",
        resource,
        JSON.stringify({
          _locale: locale === "fr" ? "fr_CA" : "en_CA",
        }),
        "",
        pathname?.split("/")[3]
      );

      setData(data);
    } catch (error) {
      console.error(error);
      push(`/${locale}/${resource}`);
    } finally {
      setLoading(false);
    }
  }, [resource]);

  const fetchFiles = useCallback(async () => {
    setImageLoading(true);
    try {
      const { data } = await CallAPI(
        "GET",
        "storage_entityfiles",
        JSON.stringify({
          refTable: resource,
          rowId: pathname?.split("/")[3],
        }),
        ""
      );

      const files = data?.["hydra:member"];

      const primary_images = files?.filter(
        (file: any) => file.type.slug === "main_image"
      );

      if (primary_images.length > 0) {
        try {
          const res = await axios.get(
            `https://demo.phpreaction.com/open-api/v3/storage_files/${primary_images[0]?.id}/bin`,
            {
              headers: {
                Authorization: `Bearer ${cookies.get("currentUser")}`,
              },
              responseType: "blob",
            }
          );

          if (res.data instanceof Blob) {
            const imageUrl = URL.createObjectURL(res.data);

            setPrimaryImage({
              url: imageUrl,
              id: primary_images[0]?.id,
            });
          } else {
            console.error("The response data is not a Blob:", res.data);
          }
        } catch (error) {
          console.error("Error fetching the primary image:", error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setImageLoading(false);
    }
  }, [resource]);

  useEffect(() => {
    if (initialLoad) {
      const query = new URLSearchParams(window.location.search);

      const filters: { [key: string]: any } = {};
      query.forEach((value, key) => {
        filters[key] = value;
      });

      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      fetchData();
      fetchFiles();
    }
  }, [initialLoad, fetchData]);

  return (
    <DetailedShowContext.Provider
      value={{
        data,
        loading,
        fetchData,
        fqcn_bui,
        primaryImage,
        imageLoading,
        managingPage,
        setManagingPage,
        managingTranslations,
        setManagingTranslations,
      }}
    >
      {children}
    </DetailedShowContext.Provider>
  );
};

const useDetailedShowContext = () => useContext(DetailedShowContext);

export { DetailedShowContextProvider, useDetailedShowContext };
