import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

// Cookies
import cookies from "js-cookie";
import GetTenant from "./getTenant";

/**
 * CallAPI
 * @param method - string
 * @param resource - string
 * @param filters - string
 * @param payload - object
 * @param id - string
 * @param domainURL - string
 *
 * @returns response
 */
export default async function CallAPI(
  method: string,
  resource: string,
  filters?: string,
  payload?: Object,
  id?: number | string,
  domainURL?: string
) {
  const axios = Axios.create({
    // timeout: 10000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const tenant = GetTenant();

  try {
    let response;
    if (method === "POST") {
      response = await axios.post("/api/callOut", payload, {
        params: { resource, filters, domainURL, tenant },
      });
    } else if (method === "PUT") {
      response = await axios.put("/api/callOut", payload, {
        params: { resource, filters, id, tenant },
      });
    } else if (method === "DELETE") {
      response = await axios.delete("/api/callOut", {
        params: { resource, filters, id, tenant },
      });
    } else {
      response = await axios.get("/api/callOut", {
        params: {
          resource,
          filters,
          id,
          tenant,
        },
      });
    }

    return response;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      cookies.remove("currentUser");
      window.location.reload();
    }

    throw err;
  }
}
