import React from "react";

// React Icons
import { IoCloseOutline } from "react-icons/io5";

// Components
import { Button } from "@/components/common/Button";
import * as Dialog from "@radix-ui/react-dialog";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";
import useAppTranslations from "@/utils/hooks/useAppTranslations";

// Site data
import { HelpModalSections } from "@/sitedata/Help";

type HelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const fqcn_bui: IFQCN_BUI = {
  Bundle: "helpModalBundle",
  Unit: "modal",
  Interface: "helpModal",
};

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  const { translate: t } = useAppTranslations();
  return (
    <Dialog.Root modal={true} open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay width-full h-full fixed inset-0 bg-black opacity-40 animate-overlayShow" />
        <Dialog.Content
          className="DialogContent overflow-y-auto rounded-lg fixed top-1/2 left-1/2 max-w-2xl h-fit p-3 md:p-6 z-[70] dark:bg-slate-700 dark:border dark:border-phpc-blue bg-white focus:outline-none shadow-2xl -translate-x-1/2 -translate-y-1/2 animate-contentShow"
          style={{
            width: "calc(100% - 40px)",
          }}
        >
          <article className="overflow-y-auto">
            <div className="IconButton w-full relative flex items-center justify-between h-6 mb-5">
              <Dialog.Title className="dark:text-white text-black font-medium text-lg text-center flex-1">
                {t("Help")}
              </Dialog.Title>
              <Dialog.Close asChild>
                <Button
                  className="outline-none bg-transparent hover:bg-transparent dark:text-white text-black h-6 right-0 absolute top-0"
                  aria-label="Close"
                  id={getFQCN(fqcn_bui, "button-close")}
                  title={t("Close")}
                  onClick={onClose}
                >
                  <IoCloseOutline className="w-7 h-7" />
                </Button>
              </Dialog.Close>
            </div>

            <div className="overflow-y-auto h-full max-h-[80vh]">
              {HelpModalSections.map((section, idx) => (
                <div key={idx} className="mb-6">
                  <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">
                    {t(section.title)}
                  </h2>
                  <ul>
                    {section.shortcuts.map((shortcut, index) => (
                      <li key={index} className="flex items-center mb-3">
                        <div className="flex items-center gap-1 mr-4">
                          {shortcut.keys.map((key, i) => (
                            <kbd
                              key={i}
                              className={`min-h-[30px] min-w-[40px] inline-flex justify-center items-center py-1 px-2 bg-gray-200 text-gray-900 border font-mono text-sm rounded-md shadow-sm dark:shadow-gray-500/50 shadow-gray-900/30 dark:bg-gray-800 dark:text-gray-200 ${
                                i !== shortcut.keys.length - 1 ? "mr-1" : ""
                              }`}
                            >
                              {key}
                            </kbd>
                          ))}
                        </div>
                        <span className="text-gray-600 dark:text-gray-400 text-md font-medium">
                          {t(shortcut.action)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default HelpModal;
